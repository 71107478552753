import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { ValidationObserver } from 'vee-validate'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

@Component({
  name: 'GtrEventSupportCheckEditForm'
})
export default class GtrEventSupportCheckEditForm extends Vue {
    @Prop({ required: true, type: Boolean, default: false })
    visible: boolean | undefined

    @Prop({ required: true, type: String })
    event_uuid: string | undefined

    @Prop({ required: true, type: Object })
    check: any | undefined

    @Ref()
    readonly observerEditSupportCheckForm!: InstanceType<typeof ValidationObserver>;

    data () {
      return {
        submitting: false,
        showForm: false,
        editSupportCheck: {
          name: null,
          description: null,
          query: null,
          uuid: null
        }
      }
    }

    @Watch('visible', { immediate: true })
    onVisibleValueChange (newVisibleValue: boolean) {
      this.$data.showForm = newVisibleValue
    }

    @Watch('check', { immediate: true })
    onLanguageChange (check: any) {
      this.$data.editSupportCheck.name = check.name
      this.$data.editSupportCheck.description = check.description
      this.$data.editSupportCheck.query = check.query
      this.$data.editSupportCheck.uuid = check.uuid
    }

    async submit () {
      const form = (this.$refs.editSupportCheckForm as HTMLFormElement)
      if (form.validate()) {
        try {
          this.$data.submitting = true
          const payload: any = {
            event_uuid: this.event_uuid,
            check_uuid: this.$data.editSupportCheck.uuid,
            data: {
              name: this.$data.editSupportCheck.name,
              description: this.$data.editSupportCheck.description,
              query: this.$data.editSupportCheck.query
            }
          }
          await this.$store.dispatch('event/updateSupportCheck', payload)
          this.$bus.$emit('gtr.level-two.edit-support-check')
          Container.get(Notification).success('Check successfully updated.')
          this.handleCloseForm()
        } catch (error) {
          Container.get(ErrorHandlerService).error(error)
        } finally {
          this.$data.submitting = false
        }
      }
    }

    handleCloseForm () {
      this.$data.editSupportCheck = {
        name: null,
        description: null,
        query: null,
        uuid: null
      }
      this.observerEditSupportCheckForm.reset()
      this.$emit('close')
    }
}
