import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { ValidationObserver } from 'vee-validate'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

@Component({
  name: 'GtrEventSupportCheckNewForm'
})
export default class GtrEventSupportCheckNewForm extends Vue {
    @Prop({ required: true, type: Boolean, default: false })
    visible: boolean | undefined

    @Prop({ required: true, type: String })
    event_uuid: string | undefined

    @Ref()
    readonly observerNewSupportCheckForm!: InstanceType<typeof ValidationObserver>;

    data () {
      return {
        submitting: false,
        showForm: false,
        newSupportCheck: {
          name: null,
          description: null,
          query: null
        }
      }
    }

    @Watch('visible', { immediate: true })
    onVisibleValueChange (newVisibleValue: boolean) {
      this.$data.showForm = newVisibleValue
    }

    handleCloseForm () {
      this.$data.newSupportCheck = {
        name: null,
        description: null,
        query: null
      }
      this.observerNewSupportCheckForm.reset()
      this.$emit('close')
    }

    async submit () {
      const form = (this.$refs.newSupportCheckForm as HTMLFormElement)
      if (form.validate()) {
        try {
          this.$data.submitting = true
          await this.$store.dispatch('event/createSupportCheck', {
            event_uuid: this.event_uuid,
            data: this.$data.newSupportCheck
          })
          this.handleCloseForm()
          this.$bus.$emit('gtr.level-two.new-support-check')
          Container.get(Notification).success('Check successfully created.')
        } catch (error) {
          Container.get(ErrorHandlerService).error(error)
        } finally {
          this.$data.submitting = false
        }
      }
    }
}
