import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import GtrEventSupportCheckNewForm from './forms/new/new.form.vue'
import GtrEventSupportCheckEditForm from './forms/edit/edit.form.vue'
import GtrEventSupportCheckDetailsForm from './forms/details/details.form.vue'

@Component({
  name: 'GtrEventSupportCheckView',
  computed: {
    ...mapState('event', ['supportChecks'])
  },
  components: {
    'gtr-new-support-check-form': GtrEventSupportCheckNewForm,
    'gtr-edit-support-check-form': GtrEventSupportCheckEditForm,
    'gtr-details-support-check-form': GtrEventSupportCheckDetailsForm
  }
})
export default class GtrEventSupportCheckView extends Vue {
  data () {
    return {
      loading: false,
      table: {
        headers: [
          { text: 'Name', align: 'start', sortable: true, value: 'name' },
          { text: 'Description', align: 'center', sortable: true, value: 'description' },
          { text: 'Query', align: 'center', sortable: true, value: 'query' },
          { text: 'Last Run', align: 'center', sortable: true, value: 'latest_run' },
          { text: 'Last Result', align: 'center', sortable: true, value: 'latest_result' },
          { text: '', sortable: false, searchable: false, value: 'actions', width: '90px' }
        ],
        items: []
      },
      forms: {
        showNewSupportCheckForm: false,
        showEditSupportCheckForm: false,
        showViewSupportCheckForm: false
      },
      supportCheckToEdit: null,
      supportCheckToView: null
    }
  }

  created () {
    this.$bus.$on('gtr.level-two.new-support-check', this.fetchSupportChecks)
    this.$bus.$on('gtr.level-two.edit-support-check', this.fetchSupportChecks)
  }

  async mounted () {
    await this.fetchSupportChecks()
  }

  @Watch('supportChecks', { immediate: true })
  onSupportChecksChange (payload: any) {
    if (payload.data) {
      this.$data.table.items = payload.data
    }
  }

  handleShowNewSupportCheckForm () {
    this.$data.forms.showNewSupportCheckForm = true
  }

  handleCloseNewSupportCheckForm () {
    this.$data.forms.showNewSupportCheckForm = false
  }

  handleShowEditSupportCheckForm (check: any) {
    this.$data.supportCheckToEdit = check
    this.$data.forms.showEditSupportCheckForm = true
  }

  handleCloseEditSupportCheckForm () {
    this.$data.supportCheckToEdit = null
    this.$data.forms.showEditSupportCheckForm = false
  }

  handleShowDetailsSupportCheckForm (check: any) {
    this.$data.supportCheckToView = check
    this.$data.forms.showViewSupportCheckForm = true
  }

  handleCloseDetailsSupportCheckForm () {
    this.$data.supportCheckToView = null
    this.$data.forms.showViewSupportCheckForm = false
  }

  private async fetchSupportChecks () {
    this.$data.loading = true
    await this.$store.dispatch('event/loadSupportChecks', { event_uuid: this.$route.params.event_uuid })
    this.$data.loading = false
  }
}
