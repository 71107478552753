import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  name: 'GtrEventSupportCheckDetailsForm',
  computed: {
    ...mapState('event', ['supportCheckResults'])
  }
})
export default class GtrEventSupportCheckDetailsForm extends Vue {
    @Prop({ required: true, type: Boolean, default: false })
    visible: boolean | undefined

    @Prop({ required: true, type: String })
    event_uuid: string | undefined

    @Prop({ required: true, type: Object })
    check: any | undefined

    data () {
      return {
        supportCheck: null,
        showForm: false,
        supportCheckResultsItems: [],
        expanded: [],
        singleExpand: false,
        loading: false,
        headers: [
          {
            text: 'Date',
            align: 'center',
            sortable: false,
            value: 'date'
          },
          {
            text: 'Passed',
            align: 'center',
            sortable: false,
            value: 'passed'
          }
        ]
      }
    }

    async mounted () {
      if (this.check !== null) {
        this.$data.loading = true
        await this.$store.dispatch('event/loadSupportCheckResults', {
          event_uuid: this.event_uuid,
          check_uuid: this.check.uuid
        })
        this.$data.loading = false
      }
    }

    @Watch('visible', { immediate: true })
    onVisibleValueChange (newVisibleValue: boolean) {
      this.$data.showForm = newVisibleValue
    }

    @Watch('check', { immediate: true })
    onLanguageChange (check: any) {
      this.$data.supportCheck = check
    }

    @Watch('supportCheckResults', { immediate: true })
    onSupportCheckResultsChange (payload: any) {
      if (payload.data) {
        this.$data.supportCheckResultsItems = payload.data
      }
    }

    handleCloseForm () {
      this.$emit('close')
    }
}
